import type { MetaDescriptor } from "@remix-run/react";
import { type ClassValue, clsx } from "clsx";
import { customAlphabet } from "nanoid";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const generateId = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  14,
);

export function timeAgo(date: Date) {
  const now = new Date();
  const elapsed = now.getTime() - date.getTime(); // Time elapsed in milliseconds

  const minutes = Math.round(elapsed / 60000);
  const hours = Math.round(elapsed / 3600000);
  const days = Math.round(elapsed / 86400000);

  if (minutes < 1) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    return date.toLocaleDateString("en", { dateStyle: "medium" });
  }
}

interface HtmlMetaDataDescriptorParams {
  title: string;
  description: string;
  baseUrl: string;
  urlPath: string;
}

/**
 * Prepares the meta tag descriptors (title, description, og and twitter tags) for remix's `meta` function.
 */
export function prepareHtmlMetaDataDescriptor({
  title,
  description,
  baseUrl,
  urlPath,
}: HtmlMetaDataDescriptorParams): MetaDescriptor[] {
  return [
    { title },
    { name: "description", content: description },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:url",
      content: `${baseUrl}${urlPath}`,
    },
    {
      property: "og:image",
      content: `${baseUrl}/og-image.png`,
    },
    {
      property: "og:image:width",
      content: "2400",
    },
    {
      property: "og:image:height",
      content: "1350",
    },
    {
      property: "og:image:type",
      content: "image/png",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "twitter:title",
      content: title,
    },
    {
      property: "twitter:description",
      content: description,
    },
    {
      property: "twitter:image",
      content: `${baseUrl}/og-image.png`,
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:domain",
      content: "annotate.dev",
    },
    {
      property: "twitter:url",
      content: `${baseUrl}${urlPath}`,
    },
    {
      tagName: "link",
      rel: "canonical",
      href: `${baseUrl}${urlPath}`,
    },
  ];
}
